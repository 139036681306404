import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faShippingFast, faSearch } from '@fortawesome/free-solid-svg-icons';
import Cart2 from '../cart/cart2';
import Search from '../search-bar/search-bar';
import { useSelector } from 'react-redux';
import './header.css';

const Header = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const products = useSelector((state) => state.cart.products);

  // Refs for Cart and Search components
  const cartRef = useRef(null);
  const searchRef = useRef(null);

  // Calculate total quantity
  const totalQuantity = products.reduce((total, product) => total + product.quantity, 0);

  const handleCartClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
    if (open2) {
      setOpen2(false);
    }
  };

  const handleSearchClick = (e) => {
    e.stopPropagation();
    setOpen2(!open2);
    if (open) {
      setOpen(false);
    }
  };

  // Close Cart2 or Search when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setOpen(false);
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setOpen2(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* Header Section 1 */}
      <div className="header-section1">
        <div className="section1-links1">
          <FontAwesomeIcon icon={faShippingFast} />
          <p className='shipping-icon'>Free Shipping for orders over $150</p>
        </div>
        <div className="section1-links2">
          <a href="/about-us">About</a>
          <a href="/contact">Contact</a>
          <p>Call Us 956-330-2103</p>
        </div>
      </div>

      {/* Header Section 2 */}
      <div className="header-section2">
        <div className="logo-container">
          <a href="/">
            <img src="/images/360_F_335627495_TYgzPi0LTM3TLYrSL5uW0wjwHqVaW6mq.jpg" alt="Logo" className="logo" />
            <span className="brand">Emtech Industrial Supply</span>
          </a>
        </div>

        <div className="header-icons">
          <p className='search-title' onClick={handleSearchClick}>Search</p>
          <div className='search-Icon' onClick={handleSearchClick}>
            <FontAwesomeIcon className='searchIcon' icon={faSearch} />
          </div>
          <div className="cartIcon" onClick={handleCartClick}>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className='totalQ'>{totalQuantity}</span> {/* Display total quantity */}
          </div>
        </div>
      </div>

      {/* Header Section 3 */}
      <div className="header-section3">
        <div className="header3-links">
          <div className="dropdown">
            <a href="/categories/automation" className="dropbtn">Automation</a>
            <div className="dropdown-content">
              <a href="/sub_categories/contactors">Contactors</a>
              <a href="/sub_categories/convayer-rollers">Convayer Rollers</a>
              <a href="/sub_categories/circuit-breakers">Circuit Breakers</a>
              <a href="/sub_categories/drives">Drives</a>
              <a href="/sub_categories/motors">Motors</a>
              <a href="/sub_categories/plc">PLC</a>
              <a href="/sub_categories/sensors">Sensors</a>
              <a href="/sub_categories/switches">Switches</a>
              <a href="/sub_categories/touch-screens">Touch Screens</a>
            </div>
          </div>

          <div className="dropdown">
            <a href="/categories/pneumatic" className="dropbtn">Pneumatic</a>
            <div className="dropdown-content">
              <a href="/sub_categories/cylinders">Cylinders</a>
              <a href="/sub_categories/fittings">Fittings</a>
              <a href="/sub_categories/gauges">Gauges</a>
              <a href="/sub_categories/manifolds">Manifolds</a>
              <a href="/sub_categories/valves">Valves</a>
              <a href="/sub_categories/actuators">Actuators</a>
              <a href="/sub_categories/regulators">Regulators</a>
            </div>
          </div>

          <div className="dropdown">
            <a href="/categories/test-equipment" className="dropbtn">Test Equipment</a>
            <div className="dropdown-content">
              <a href="/sub_categories/battery-testers">Battery Testers</a>
              <a href="/sub_categories/clamp-meters">Clamp Meters</a>
              <a href="/sub_categories/dataloggers">Dataloggers</a>
              <a href="/sub_categories/ground-testers">Ground Testers</a>
              <a href="/sub_categories/multimeters">Multimeters</a>
              <a href="/sub_categories/oscilloscopes">Oscilloscopes</a>
              <a href="/sub_categories/power-supplies">Power Supplies</a>
            </div>
          </div>

          <div className="dropdown">
            <a href="/categories/accessories" className="dropbtn">Accessories</a>
            <div className="dropdown-content">
              <a href="/sub_categories/accessory-modules">Accessory Modules</a>
              <a href="/sub_categories/test-probes">Test Probes</a>
              <a href="/sub_categories/saws">Saws</a>
            </div>
          </div>

          <div className="dropdown">
          <a href="/categories/components" className="dropbtn">Components</a>
            <div className="dropdown-content">
              <a href="/sub_categories/component-modules">Component Modules</a>
              <a href="/sub_categories/relays">Relays</a>
            </div>
          </div>

          <div className="dropdown">
            <a href="/categories/tools" className="dropbtn">Tools</a>
            <div className="dropdown-content">
              <a href="/sub_categories/hand-tools">Hand Tools</a>
              <a href="/sub_categories/power-tools">Power Tools</a>
              <a href="/sub_categories/pneumatic-tools">Pneumatic Tools</a>
              <a href="/sub_categories/soldering-equipment">Soldering Equipment</a>
            </div>
          </div>
          <div className="dropdown">
            <a href="/categories/education" className="dropbtn">Education</a>
            <div className="dropdown-content">
              <a href="/sub_categories/breadboards">Breadboards</a>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div ref={cartRef}>
          <Cart2 setOpen={setOpen} />
        </div>
      )}
      {open2 && (
        <div ref={searchRef}>
          <Search setOpen2={setOpen2} />
        </div>
      )}
    </div>
  );
};

export default Header;
