import React from "react";
import Card from "../productCard/productCard";
import "./featuredProducts.scss";
import useFetch from "../../hooks/useFetch";

const FeaturedProducts = ({ type }) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  return (
    <div className="featuredProducts">
      <div className="top">
        <h3>{type} Products</h3>
      </div>
      <div className="bottom">
        {error ? (
          "Something went wrong!"
        ) : loading ? (
          "loading"
        ) : (
          <div className="product-list">
            {data && data.map((item) => (
              <div key={item.id} className="product-item">
                <Card className="card-contain" item={item} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeaturedProducts;
