import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import List from "../../components/categories-list/list";
import useFetch from "../../hooks/useFetch";
import "./categories.scss";

const Collection1 = () => {
  const { title } = useParams();
  const [maxPrice, setMaxPrice] = useState(10000);
  const [sort, setSort] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Fetch brands data
  const { data: brandsData } = useFetch(`/brands`);

  

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedBrands(
      isChecked
        ? [...selectedBrands, value]
        : selectedBrands.filter((item) => item !== value)
    );
  };

  return (
    <div className="collection">
      <div className="title-container">
        <h1 className="title">{title}</h1>
      </div>
      
      <div className="breadcrumbs">
  
        <Link className = 'text1' to="/">home</Link> /
        {title}
       
      </div>

      <div className="products">
        <div className="left">
          <div className="filterItem">
            <h2>Featured Brands</h2>
            {brandsData &&
              brandsData.map((item) => (
                <div className="inputItem" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    value={item.id}
                    onChange={handleChange}
                  />
                  <label htmlFor={item.id}>
                    <p>{item.attributes.title}</p>
                  </label>
                </div>
              ))}
          </div>
          <div className="filterItem">
            <h2>Filter by price</h2>
            <div className="inputItem">
              <span>0</span>
              <input
                type="range"
                min={0}
                max={10000}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
              <span>{maxPrice}</span>
            </div>
          </div>
          <div className="filterItem">
            <h2>Sort by</h2>
            <div className="inputItem">
              <input
                type="radio"
                id="asc"
                value="asc"
                name="price"
                onChange={(e) => setSort("asc")}
              />
              <label htmlFor="asc">Price (Lowest first)</label>
            </div>
            <div className="inputItem">
              <input
                type="radio"
                id="desc"
                value="desc"
                name="price"
                onChange={(e) => setSort("desc")}
              />
              <label htmlFor="desc">Price (Highest first)</label>
            </div>
          </div>
        </div>
        <div className="right">
          <List title={title} maxPrice={maxPrice} sort={sort} brands={selectedBrands} />
        </div>
      </div>
    </div>
  );
};

export default Collection1;
