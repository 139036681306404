import React, { useEffect, useState } from 'react';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { removeItem, updateQuantity, resetCart } from '../../redux/cartReducer';
import { makeRequest } from '../../makeRequest';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import './cart.scss';

const Cart = () => {
  const [quantities, setQuantities] = useState({});
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialQuantities = {};
    products.forEach((item) => {
      initialQuantities[item.id] = item.quantity;
    });
    setQuantities(initialQuantities);
  }, [products]);

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity <= 0) {
      dispatch(removeItem(itemId));
    } else {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [itemId]: newQuantity,
      }));
      dispatch(updateQuantity({ productId: itemId, quantity: newQuantity }));
    }
  };

  const totalPrice = () => {
    return products.reduce((total, item) => total + item.quantity * item.price, 0).toFixed(2);
  };

  const stripePromise = loadStripe('pk_live_51NaTvbBriBbxyOm86cYCoK062FQ19FvKzGIJMJWHoQMrBkslADrh5p67uPKeBdbw8v4MAdYqo4DgXuR25Qcyk5q800HacqDqLK');

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post('/orders', { products });
      const { error } = await stripe.redirectToCheckout({ sessionId: res.data.stripeSession.id });
      if (error) {
        console.error('Payment initiation error:', error);
        alert('Failed to initiate payment. Please try again.');
      } else {
        const interval = setInterval(async () => {
          const sessionRes = await makeRequest.get(`/checkout-session/${res.data.stripeSession.id}`);
          if (sessionRes.data.payment_status === 'paid') {
            clearInterval(interval);
            dispatch(resetCart());
            window.location.href = '/thank-you';
          }
        }, 3000);
      }
    } catch (err) {
      console.error('Payment initiation error:', err);
      alert('Failed to initiate payment. Please try again.');
    }
  };

  useEffect(() => {
    console.log('Products:', products);
  }, [products]);

  if (products.length === 0) {
    return (
      <div className="cart-empty-page">
        <h1>Your cart is empty</h1>
        <Link to="/">
          <button className="return-home">Return Home</button>
        </Link>
      </div>
    );
  }

  return (
    <div className="cart-container1">
      <h4>Your Cart</h4>
      <div className="cart-titles">
        <div className="product-title">PRODUCT</div>
        <div className="quantity-title">QUANTITY</div>
        <div className="price-title">PRICE</div>
      </div>
      <hr />
      {products.map((item) => (
        <div className="item" key={item.id}>
           <Link to={`/products/${encodeURIComponent(item.product_name)}`} className="product-link">
            <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
            </Link>
          <div className="details">
            <Link to={`/products/${encodeURIComponent(item.product_name)}`} className="product-link">
              <h5>{item.title?.substring(0, 50)}</h5>
            </Link>
          </div>
          <div className="quantity">
            <button onClick={() => handleQuantityChange(item.id, quantities[item.id] - 1)}>-</button>
            {quantities[item.id]}
            <button onClick={() => handleQuantityChange(item.id, quantities[item.id] + 1)}>+</button>
          </div>
          <div className="trash-can">
            <IoTrashBinOutline onClick={() => dispatch(removeItem(item.id))} />
          </div>
          <div className="price1">${item.price}</div>
        </div>
      ))}
      <hr />
      <div className="order-summary">
        <div className="estimated-total">
          <h5>Estimated Total: </h5>
          <span>${totalPrice()}</span>
        </div>
        <p className="disclaimer">Taxes and shipping calculated at checkout</p>
        <button className="checkout" onClick={handlePayment}>PROCEED TO CHECKOUT</button>
      </div>
    </div>
  );
};

export default Cart;
