import React, { useEffect, useState, useRef } from 'react';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from "react-redux";
import { removeItem, updateQuantity } from '../../redux/cartReducer';
import { makeRequest } from "../../makeRequest";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom';
import './cart2.scss';

const Cart2 = () => {
  const [quantities, setQuantities] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartRef = useRef(null);

  useEffect(() => {
    const initialQuantities = {};
    products.forEach((item) => {
      initialQuantities[item.id] = item.quantity;
    });
    setQuantities(initialQuantities);
  }, [products]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        console.log('Clicked outside cart'); // Debugging line
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity <= 0) {
      dispatch(removeItem(itemId));
    } else {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [itemId]: newQuantity,
      }));
      dispatch(updateQuantity({ productId: itemId, quantity: newQuantity }));
    }
  };

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity * item.price;
    });
    return total.toFixed(2);
  };

  if (products.length === 0) {
    return (
      <div className="cart">
        <h1 >Cart</h1>
        <h4>Your cart is empty</h4>
      </div>
    );
  }

  const stripePromise = loadStripe(
    "pk_live_51NaTvbBriBbxyOm86cYCoK062FQ19FvKzGIJMJWHoQMrBkslADrh5p67uPKeBdbw8v4MAdYqo4DgXuR25Qcyk5q800HacqDqLK"
  );

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post("/orders", { products });
      const { error } = await stripe.redirectToCheckout({ sessionId: res.data.stripeSession.id });
      if (error) {
        console.error(error);
        alert('Failed to initiate payment. Please try again.');
      }
    } catch (err) {
      console.error(err);
      alert('Failed to initiate payment. Please try again.');
    }
  };

  const handleProceedToCart = () => {
    setIsVisible(false);
    navigate('/cart');
  };

  return (
    isVisible && (
      <div className="cart" ref={cartRef}>
        <h1>Cart</h1>
        <h4>Products in your cart</h4>
        {products.map((item) => (
          <div className="item" key={item.id}>
            <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
            <div className="details">
              <h5>{item.title?.substring(0, 50)}</h5>
              <div className="price1">
                <p>${item.price}</p>
                <p>Qty:</p>
                <button onClick={() => handleQuantityChange(item.id, quantities[item.id] - 1)}>-</button>
                {quantities[item.id]}
                <button onClick={() => handleQuantityChange(item.id, quantities[item.id] + 1)}>+</button>
              </div>
            </div>
            <div className="trash-can">
              <IoTrashBinOutline onClick={() => dispatch(removeItem(item.id))} />
            </div>
          </div>
        ))}
        <div className="total">
          <span>SUBTOTAL</span>
          <span>${totalPrice()}</span>
        </div>
        <div className="buttons">
          <button className="cart2" onClick={handleProceedToCart}>PROCEED TO CART</button>
          <button className='checkout' onClick={handlePayment}>PROCEED TO CHECKOUT</button>
        </div>
      </div>
    )
  );
};

export default Cart2;
